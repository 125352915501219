import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const NotFoundPage = props => {
  const pageTitle = 'Not Found'
  const pageSlug = 'notfound'

  return (
    <Layout location={props.location}>
      <SEO pageTitle={pageTitle} pageSlug={pageSlug} />
      <div className="container" style={{ marginTop: '5rem', height: '70vh' }}>
        <h1>{pageTitle}</h1>
        <p style={{ marginTop: '5rem' }}>
          You just hit a route that doesn&#39;t exist.
          <br />
          <a
            href="/"
            style={{
              marginTop: '5rem',
              color: '#4a7899',
              marginTop: '1rem',
              display: 'block',
            }}
          >
            Home
          </a>
        </p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
